import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAppContext } from '../../../../../contexts/appContext';
import { DjApiPaths } from '../ApiPaths';

export const useRemoveModel = ({ agentManagerId }: { agentManagerId: string }) => {
    const { user } = useAppContext();
    const queryClient = useQueryClient();

    const removeModel = async (modelId: string) => {
        const url = new URL(DjApiPaths.RemoveModel(agentManagerId));
        const response = await fetch(url.href, {
            method: 'POST',
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${user.siberiaToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                model_id: modelId,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to remove model.');
        }
        queryClient.invalidateQueries({
            queryKey: [DjApiPaths.ModelMetrics(agentManagerId)],
        });
        queryClient.invalidateQueries({
            queryKey: [DjApiPaths.AllocationMetrics(agentManagerId)],
        });
        queryClient.invalidateQueries({
            queryKey: [DjApiPaths.Config(agentManagerId)],
        });
    };
    const { mutate, error, isPending } = useMutation({ mutationFn: removeModel });

    return {
        removeModelIsLoading: isPending,
        removeModelError: error as Error,
        removeModel: mutate,
    };
};
