import { RollingMetricsData } from '../types/RollingMetric';
import { DjApiPaths } from '../ApiPaths';
import useApiQuery from '../../../../../hooks/useApiQuery';

interface UseRollingMetricsDataParams {
    agentManagerId: string;
}

export const useRollingMetricsData = ({
    agentManagerId,
}: UseRollingMetricsDataParams): [RollingMetricsData[], Error | undefined, boolean] => {
    const {
        data = [],
        error,
        isFetching,
    } = useApiQuery<RollingMetricsData[]>({
        url: DjApiPaths.RollingMetrics(agentManagerId),
        disabled: !agentManagerId,
    });

    return [data, error as Error, isFetching];
};
