import { DataCatalogApiPaths } from '../components/console/experiments/DMS/ApiPaths';
import { ModelOutput } from '../components/console/experiments/DMS/types/ModelOutput';
import useApiQuery from './useApiQuery';

export const useModelOutputs = ({
    disabled,
    namespace,
    usecase,
}: {
    disabled: boolean;
    namespace?: string;
    usecase?: string;
}) =>
    useApiQuery<ModelOutput[]>({
        url: DataCatalogApiPaths.ModelOutputs(namespace, usecase),
        disabled: !namespace || disabled,
    });
