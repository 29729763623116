import React from 'react';
import _ from 'lodash';
import { Table } from '@mantine/core';
import { GradientDot } from '../../../../utils/GradientDot';
import { ModelMetricsData } from '../types/ModelMetric';

interface ModelPerformanceRowProps {
    model: ModelMetricsData;
    cols: string[];
}

export const ModelPerformanceRow: React.FC<ModelPerformanceRowProps> = ({ model, cols }) => (
    <Table.Tr key={model?.action__model_id} className="cursor-pointer hover:bg-primary-3">
        <Table.Td className="flex items-center gap-2 px-2 py-1">
            <GradientDot color={model?.['color']} />
            {model?.model_name}
        </Table.Td>
        {cols.map(row => (
            <Table.Td className="w-48" key={row}>
                {model?.[row] ? (Number(model?.[row]) * 100).toFixed(2) : 'N/A' ?? '---'}
                {model?.[row] ? '%' : ''}
            </Table.Td>
        ))}
    </Table.Tr>
);
