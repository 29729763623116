import React, { PropsWithChildren, ReactElement } from 'react';
import cx from 'classnames';
import { Warning } from 'phosphor-react';
import { BareCard } from 'andromeda/Card';
import NoDataCard from 'andromeda/noDataCard';
import { primaryColors } from '@/constants/colors';
import { Spinner } from 'andromeda';

interface ChartContainerProps {
    title: string | JSX.Element;
    subtitle?: string;
    action?: React.ReactNode;
    loading?: boolean;
    error?: string;
    className?: string;
    childrenClassName?: string;
    disableMinHeight?: boolean;
    noDataFound?: boolean;
    errorSubtext?: string;
    errorIcon?: ReactElement;
    titleClassName?: string;
}

export const ChartContainer = ({
    title,
    subtitle,
    action,
    className,
    loading,
    error,
    children,
    childrenClassName,
    disableMinHeight,
    errorSubtext,
    errorIcon,
    titleClassName,
    noDataFound,
}: PropsWithChildren<ChartContainerProps>) => {
    const commonClassName = cx('flex items-center justify-center bg-shadow-200', className);

    if (loading) {
        return (
            <BareCard
                className={cx(commonClassName, {
                    'min-h-[400px]': !disableMinHeight,
                })}
            >
                <Spinner />
            </BareCard>
        );
    }

    if (error) {
        return (
            <BareCard
                className={cx(commonClassName, 'flex flex-col text-2xl text-gray-400 gap-2', {
                    'min-h-[400px]': !disableMinHeight,
                })}
            >
                <>
                    {errorIcon || <Warning size={32} />}
                    {error}
                </>
                <div className="mt-2 text-lg">{errorSubtext}</div>
            </BareCard>
        );
    }

    return (
        <BareCard className={className}>
            <div className="flex justify-between">
                <div>
                    <h5 className={cx('text-xl font-semibold', titleClassName)}>{title}</h5>
                    {subtitle && <span className="mt-1 text-navy-solid-70">{subtitle}</span>}
                </div>
                {action}
            </div>
            <div
                className={cx(childrenClassName, {
                    'min-h-[400px]': !disableMinHeight,
                    'flex items-center justify-center': noDataFound,
                })}
            >
                {noDataFound ? <NoDataCard>No Data Found</NoDataCard> : children}
            </div>
        </BareCard>
    );
};
