import { useExperimentsQueryParams } from '../useExperimentsQueryParams';
import { AllocationByUserSegment } from './AllocationByUserSegment';
import { ModelPerformance } from './ModelPerformance/ModelPerformance';
import { RewardFnOverTime } from './RewardFnOverTime';

export const ExperimentResults = ({
    agentManagerModelId,
    isLoading,
}: {
    agentManagerModelId?: string;
    isLoading?: boolean;
}) => {
    const [{ agentManagerModelId: agentManagerModelIdParam }] = useExperimentsQueryParams();
    return (
        <div className="mb-6 mt-4 space-y-4">
            <section className="grid auto-cols-fr gap-4 lg:grid-cols-2">
                <RewardFnOverTime
                    agentManagerModelId={agentManagerModelIdParam ?? agentManagerModelId}
                    agentManagerModelIsLoading={isLoading}
                />
                <AllocationByUserSegment
                    agentManagerModelId={agentManagerModelIdParam ?? agentManagerModelId}
                    agentManagerModelIsLoading={isLoading}
                />
            </section>
            <ModelPerformance
                agentManagerModelId={agentManagerModelIdParam ?? agentManagerModelId}
                agentManagerModelIsLoading={isLoading}
            />
        </div>
    );
};
