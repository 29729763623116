import styled from '@emotion/styled';
import { Button, Modal, Select, Table } from '@mantine/core';
import _ from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import StyledTable from 'andromeda/styledTable';
import { formatUseCaseName } from '@/components/utils/UseCaseDropdown';
import { SelectItem } from 'andromeda/types/select/common';
import { ChartContainer } from '../ChartContainer';
import { ModelPerformanceRow } from './ModelPerformanceRow';

import { categoryColors } from '../../../../../constants/colors';
import ErrorText from '../../../../utils/errorText';
import { useCreateModel } from '../hooks/useCreateModel';
import { useModelMetricsData } from '../hooks/useModelMetrics';
import { useModelOutputs } from '../../../../../hooks/useModelOutputs';
import { useRemoveModel } from '../hooks/useRemoveModel';
import StyledTooltip from '../../../../utils/styledTooltip';
import { useExperimentsQueryParams } from '../../useExperimentsQueryParams';

const formSchema = z.object({
    model: z.string().min(1, { message: 'Model is required' }),
});

type FormState = z.infer<typeof formSchema>;

export const ModelPerformance = ({
    agentManagerModelId,
    agentManagerModelIsLoading,
}: {
    agentManagerModelId: string;
    agentManagerModelIsLoading?: boolean;
}) => {
    const [isAddModelModalOpen, setIsAddModelModalOpen] = useState(false);
    const [{ usecase, namespace }] = useExperimentsQueryParams();

    const { data: modelOutputsData } = useModelOutputs({
        disabled: !isAddModelModalOpen,
        usecase,
        namespace,
    });
    const [modelMetricsData, modelMetricsError, modelMetricsIsLoading] = useModelMetricsData({
        agentManagerId: agentManagerModelId,
    });
    const { handleSubmit, getValues, control, reset } = useForm<FormState>({
        defaultValues: {
            model: '',
        },
        resolver: zodResolver(formSchema),
    });

    const { createModelError, createModelIsLoading, createModel } = useCreateModel({
        agentManagerId: agentManagerModelId,
    });
    const { removeModelError } = useRemoveModel({
        agentManagerId: agentManagerModelId,
    });

    const modelOptions = useMemo(
        () =>
            Object.values(
                // ? sometimes it changes between array and object and causing the app to break.
                ((Array.isArray(modelOutputsData) && modelOutputsData) || [])?.reduce(
                    (acc: Record<string, SelectItem>, { model_id, ingested_time }) => ({
                        ...acc,
                        [model_id]: {
                            label: `${model_id} (Last Updated: ${moment(ingested_time)
                                .utc()
                                .format('YYYY-MM-DD HH:mm')})`,
                            value: model_id,
                        },
                    }),
                    {},
                ),
            ),
        [modelOutputsData],
    );

    const onSubmit = () => {
        createModel(getValues().model);
        setIsAddModelModalOpen(false);
        reset();
    };

    const filteredData = useMemo(
        () =>
            modelMetricsData?.reduce((acc, row, index) => {
                const key = row?.action__model_id;
                acc[key] = {
                    ...(acc?.[key] ?? {}),
                    action__model_id: row?.action__model_id,
                    [row?.reward]: row?.reward_value,
                    color: categoryColors[row?.action__model_id],
                    model_name: row?.model_name,
                };
                return acc;
            }, {}) ?? {},
        [modelMetricsData],
    );

    const COLUMNS = {
        QUICK_ORDER: ['orders_influenced', 'products_influenced', 'precision', 'recall', 'mape'],
        REST: ['orders_influenced', 'products_influenced'],
    };
    return (
        <>
            <ChartContainer
                className="!mt-4 !min-h-[150px]"
                childrenClassName="!min-h-[150px]"
                title="Model Performance"
                action={
                    <StyledTooltip label="Functionality coming soon">
                        <span>
                            <Button
                                disabled
                                variant="outline"
                                classNames={{ loader: 'hidden' }}
                                onClick={() => setIsAddModelModalOpen(true)}
                            >
                                Add a new model
                            </Button>
                        </span>
                    </StyledTooltip>
                }
                loading={modelMetricsIsLoading || agentManagerModelIsLoading}
                error={modelMetricsError?.message}
            >
                <StyledTable
                    dataCondition={modelMetricsData.length === 0}
                    headers={
                        <Table.Tr className="text-left text-primary">
                            <Table.Th className="w-1/3 px-2">Model Name</Table.Th>
                            {COLUMNS[usecase === 'QUICK_ORDER' ? 'QUICK_ORDER' : 'REST'].map(
                                row => (
                                    <Table.Th key={row} className="!text-center">
                                        {formatUseCaseName(row)}
                                    </Table.Th>
                                ),
                            )}
                        </Table.Tr>
                    }
                    body={Object.keys(filteredData).map((modelMetric, i) => (
                        <ModelPerformanceRow
                            key={modelMetric}
                            model={filteredData?.[modelMetric]}
                            cols={COLUMNS[usecase === 'QUICK_ORDER' ? 'QUICK_ORDER' : 'REST']}
                        />
                    ))}
                />
            </ChartContainer>
            <Modal
                styles={{
                    body: {
                        paddingBottom: '8px',
                    },
                }}
                size="lg"
                opened={isAddModelModalOpen}
                onClose={() => setIsAddModelModalOpen(false)}
                title="Add Model"
            >
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            name="model"
                            control={control}
                            render={({ field, fieldState }) => (
                                <Select
                                    data={modelOptions}
                                    placeholder="Select a model"
                                    label="Select a model"
                                    // dropdownPosition="bottom"
                                    {...field}
                                    error={fieldState.error?.message}
                                />
                            )}
                        />
                        <div className="mt-8 flex justify-end">
                            <Button
                                type="submit"
                                loading={createModelIsLoading}
                                variant="outline"
                                classNames={{ loader: !createModelIsLoading && 'hidden' }}
                            >
                                Add Model
                            </Button>
                        </div>
                        {createModelError && <ErrorText error={createModelError} />}
                        {removeModelError && <ErrorText error={removeModelError} />}
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};
