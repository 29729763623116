import React, { useCallback, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    CategoryScale,
    ChartData,
    Chart as ChartJS,
    ChartOptions,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    Plugin,
    PointElement,
    ScriptableContext,
    TimeScale,
    Title,
    Tooltip,
    TooltipLabelStyle,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-moment';

import { AnyObject } from 'chart.js/dist/types/basic';
import {
    border,
    DEFAULT_CHART_POINT_RADIUS,
    DEFAULT_CHART_TENSION,
    defaultChartTextColor,
    defaultTooltip,
} from '../../../constants/chart';

import type {
    LineChartOptionsPlugins,
    LineChartOptionsScale,
    LineChartType,
    PointType,
} from '../../../types/components/charts';

type LineChartProps = {
    data: ChartData<'line', PointType[]>;
    xAxis?: Partial<LineChartOptionsScale['x']>;
    yAxis?: Partial<LineChartOptionsScale['y']>;
    extraYAxis?: Record<string, Partial<LineChartOptionsScale['y']>>;
    chartTitle?: string | string[];
    className?: string;
    label?: LineChartOptionsPlugins['tooltip']['callbacks']['label'];
    options?: ChartOptions<'line'>;
    tooltipTitle?: LineChartOptionsPlugins['tooltip']['callbacks']['title'];
    tooltip?: LineChartOptionsPlugins['tooltip'];
    legend?: LineChartOptionsPlugins['legend'];
    id?: string;
    tension?: number;
    annotation?: LineChartOptionsPlugins['annotation'];
    pluginsArray?: Plugin<'line', AnyObject>[];
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    Filler,
    annotationPlugin,
    Title,
);

const LineChart = React.forwardRef(
    (
        {
            data,
            chartTitle,
            label,
            xAxis = {},
            yAxis = {},
            extraYAxis,
            tooltipTitle,
            className,
            tooltip = {},
            id,
            legend = {},
            options,
            tension = DEFAULT_CHART_TENSION,
            annotation,
            pluginsArray,
        }: LineChartProps,
        ref: React.MutableRefObject<LineChartType>,
    ) => {
        const [noAnimation, setNoAnimation] = useState(false);
        const chartOptions = useMemo<ChartOptions<'line'>>(() => {
            if (options) return options;
            const totalDuration = 10000;
            const delayBetweenPoints = totalDuration / data.datasets.length;
            const previousY = (ctx: ScriptableContext<'line'>) =>
                ctx.dataset?.[ctx.datasetIndex - 1]?.['y'] ?? 0;
            // ctx.dataIndex === 0
            //     ? ctx.chart.scales.y.getPixelForValue(100)
            //     : ctx.chart
            //           .getDatasetMeta?.(ctx.datasetIndex)
            //           .data[ctx.datasetIndex - 1]?.getProps(['y'], true)?.y;

            const animation = {};

            const y: LineChartOptionsScale['y'] = {
                border,
                ...yAxis,
                title: {
                    color: defaultChartTextColor,
                    display: true,
                    ...yAxis.title,
                },
            };
            let extraYs = {};
            if (extraYAxis) {
                extraYs = Object.entries(extraYAxis).reduce(
                    (acc: typeof extraYAxis, [axisKey, axisData]) => ({
                        ...acc,
                        [axisKey]: {
                            ...y,
                            ...axisData,
                            title: { ...y.title, ...(axisData?.title || {}) },
                            border: { ...y.border, ...(axisData?.border || {}) },
                        },
                    }),
                    {},
                );
            }
            let delayed = 0;
            return {
                animation: {
                    onComplete() {
                        delayed = -1;
                    },
                    delay(ctx) {
                        if (ctx.type !== 'data' || delayed === -1 || ctx.mode !== 'default') {
                            return 0;
                        }
                        let delaying = 1000 / ctx.dataset.data.length;
                        delaying = delaying > 100 ? 100 : delaying;
                        return ctx.dataIndex * delaying + ctx.datasetIndex * 50;
                    },
                    easing: 'linear',
                },
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    line: {
                        borderWidth: 1.5,
                        tension,
                    },
                    point: {
                        borderColor: 'transparent',
                        pointStyle: 'rectRounded',
                        radius: DEFAULT_CHART_POINT_RADIUS,
                    },
                },
                scales: {
                    x: {
                        border,
                        ...xAxis,
                        title: {
                            display: true,
                            color: defaultChartTextColor,
                            ...(xAxis?.title || {}),
                        },
                    },
                    y,
                    ...extraYs,
                },
                plugins: {
                    title: {
                        display: !!chartTitle,
                        text: chartTitle,
                        padding: {
                            top: 10,
                            bottom: 10,
                        },
                        color: defaultChartTextColor,
                    },
                    legend: {
                        position: 'top' as const,
                        display: true,
                        labels: {
                            usePointStyle: true,
                            pointStyle: 'rectRounded',
                            ...(legend?.labels || {}),
                        },
                        ...legend,
                    },
                    tooltip: {
                        ...defaultTooltip,
                        title: tooltipTitle,
                        ...tooltip,
                        callbacks: {
                            label,
                            labelColor(this, tooltipItem) {
                                return {
                                    backgroundColor: 'black',
                                    borderColor: tooltipItem.dataset.borderColor,
                                    borderDash: tooltipItem.dataset.borderDash,
                                    borderDashOffset: tooltipItem.dataset.borderDashOffset,
                                    borderWidth: 4,
                                    borderRadius: 1,
                                } as TooltipLabelStyle;
                            },
                            labelTextColor: () => defaultChartTextColor,
                            ...(tooltip?.callbacks || {}),
                        },
                        titleColor: defaultChartTextColor,
                    },
                    annotation,
                },
                // ? This is because there's a weird type error. Type 'category' not assigned to line | category!
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [chartTitle, legend, tooltipTitle, tooltip, label, annotation, noAnimation]);

        return (
            <Line
                ref={ref}
                options={chartOptions}
                data={data}
                className={className}
                id={id}
                plugins={pluginsArray}
            />
        );
    },
);

export default LineChart;
