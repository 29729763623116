import { ModelMetricsData } from '../types/ModelMetric';
import { DjApiPaths } from '../ApiPaths';
import useApiQuery from '../../../../../hooks/useApiQuery';

interface UseModelMetricsDataParams {
    agentManagerId: string;
}

export const useModelMetricsData = ({
    agentManagerId,
}: UseModelMetricsDataParams): [ModelMetricsData[], Error | undefined, boolean] => {
    const { data, isFetching, error } = useApiQuery<ModelMetricsData[]>({
        url: DjApiPaths.ModelMetrics(agentManagerId),
        disabled: !agentManagerId,
    });

    return [data ?? [], error as Error, isFetching];
};
