import Color from 'color';
import React from 'react';

interface GradientDotProps {
    color: string;
}

export const GradientDot: React.FC<GradientDotProps> = ({ color }) => (
    <div
        className="flex size-3 items-center justify-center rounded-full"
        style={{ backgroundColor: Color(color).fade(0.5).toString() }}
    >
        <div className="size-[6px] rounded-full" style={{ backgroundColor: color }} />
    </div>
);
