import { useMemo } from 'react';
import _ from 'lodash';
import { DEFAULT_CHART_POINT_RADIUS } from '@/constants/chart';
import { RollingMetricsData } from '../types/RollingMetric';
import { categoryColors } from '../../../../../constants/colors';

export const useRewardsFnOverTimeChartData = (rollingMetricsData: RollingMetricsData[]) => {
    const groupingData = useMemo(
        () =>
            rollingMetricsData?.reduce(
                (
                    acc: {
                        data: Record<string, Record<string, RollingMetricsData>>;
                        labels: Record<string, string>;
                    },
                    item,
                ) => ({
                    ...acc,
                    data: {
                        ...acc.data,
                        [item.model_id]: {
                            ...(acc.data?.[item.model_id] ?? {}),
                            [item.decision_date]: {
                                ...(acc.data?.[item.model_id]?.[item.decision_date] ?? {}),
                                ...item,
                                rolling_average_reward:
                                    acc.data?.[item.reward_name]?.[item.model_id]?.[
                                        item.decision_date
                                    ]?.rolling_average_reward ?? item?.rolling_average_reward,
                            } as RollingMetricsData,
                        },
                    },
                    labels: { ...acc.labels, [item.decision_date]: item.decision_date },
                }),
                { data: {}, labels: {} },
            ),
        [rollingMetricsData],
    );

    const rollingMetricsChartData = useMemo(() => {
        const datasets: any = Object.entries(groupingData.data).map(([model_id, metricsByDate]) => {
            const metrics: RollingMetricsData[] = Object.values(metricsByDate);
            return {
                label: metrics[0].model_name,
                data: metrics.map(item => ({
                    x: item.decision_date,
                    y: item.rolling_average_reward,
                })),
                borderColor: categoryColors[model_id],
                backgroundColor: categoryColors[model_id],
                fill: metrics.length === 1,
                type: (metrics.length === 1 && 'bubble') || 'line',
                pointRadius: metrics.length === 1 ? 4 : DEFAULT_CHART_POINT_RADIUS,
            };
        });
        return { datasets, labels: Object.keys(groupingData.labels) };
    }, [groupingData.data, groupingData.labels]);

    return rollingMetricsChartData;
};
