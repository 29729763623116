import { AllocationMetric } from '../types/AllocationMetric';
import { DjApiPaths } from '../ApiPaths';
import useApiQuery from '../../../../../hooks/useApiQuery';

export const useAllocationMetricsData = ({
    agentManagerId,
}: {
    agentManagerId: string;
}): [AllocationMetric[], Error | undefined, boolean] => {
    const {
        data = [],
        isFetching,
        error,
    } = useApiQuery<AllocationMetric[]>({
        url: DjApiPaths.AllocationMetrics(agentManagerId),
        disabled: !agentManagerId,
    });

    return [data, error as Error, isFetching];
};
