import _ from 'lodash';
import useRouterQuery from '../../../hooks/useRouterQuery';
import { useUserAllowedNamespaces } from '../../../hooks/useUserAllowedNamespaces';

interface QueryParams {
    merchantCode: string;
    namespace: string;
    usecase: string;
    metric: string;
    currentView: 'overview' | 'launch' | 'results';
    agentManagerModelId?: string;
    expStartDate?: string;
    expEndDate?: string;
}

export const useExperimentsQueryParams = () => {
    const { router, handleURLParamsChange } = useRouterQuery<keyof QueryParams>();
    const query = router.query;

    const namespacesUserAllowed = useUserAllowedNamespaces();

    const defaultResultsQueryParams: QueryParams = {
        merchantCode: '576f0005-faab-4c7c-90d1-0a458a5135e2',
        namespace: (query?.namespace as string) ?? namespacesUserAllowed[0] ?? 'PH-NESTLE',
        usecase: (query?.usecase as string) ?? 'CROSS_SELL_UP_SELL',
        metric: 'Gross Revenue',
        currentView: 'overview',
        agentManagerModelId: query?.agentManagerModelId as string,
    };

    return [
        _.defaults(query, defaultResultsQueryParams) as QueryParams,
        handleURLParamsChange,
    ] as const;
};
