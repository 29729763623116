import {
    BarChartOptionsPlugins,
    BoxPlotChartOptionsPlugins,
    LineChartOptionsPlugins,
} from '../types/components/charts';

const DEFAULT_CHART_TENSION = 0.3;
const DEFAULT_CHART_POINT_RADIUS = 0;

const border = {
    dash: [3, 3],
    color: 'rgba(255, 255, 255, 0.05)',
};

const defaultChartTextColor = '#B1B5C1';

const defaultTooltip: LineChartOptionsPlugins['tooltip'] = {
    position: 'nearest',
    footerAlign: 'right',
    footerFont: {
        size: 11,
        weight: 'normal',
    },
    usePointStyle: true,
    intersect: false,
    borderWidth: 2,
    padding: 9,
};

const defaultTitle: LineChartOptionsPlugins['title'] = {
    color: '#B1B5C1',
    font: {
        size: 14,
    },
};

const defaultTooltipBar: BarChartOptionsPlugins['tooltip'] = {
    position: 'nearest',
    footerAlign: 'right',
    footerFont: {
        size: 11,
        weight: 'normal',
    },
    usePointStyle: true,
    intersect: false,
    borderWidth: 2,
    padding: 9,
};
const defaultTooltipBoxPlot: BoxPlotChartOptionsPlugins['tooltip'] = {
    position: 'nearest',
    footerAlign: 'right',
    footerFont: {
        size: 11,
        weight: 'normal',
    },
    usePointStyle: true,
    intersect: false,
    borderWidth: 2,
    padding: 9,
};

const defaultTitleBar: BarChartOptionsPlugins['title'] = {
    color: '#B1B5C1',
    font: {
        size: 14,
    },
};

export {
    DEFAULT_CHART_POINT_RADIUS,
    DEFAULT_CHART_TENSION,
    border,
    defaultTooltip,
    defaultTitle,
    defaultTooltipBar,
    defaultTitleBar,
    defaultChartTextColor,
    defaultTooltipBoxPlot,
};
