import { useMemo, useState } from 'react';
import Select from 'andromeda/selects/select';
import { SelectItem } from 'andromeda/types/select/common';
import LineChart from '../../../utils/charts/lineChart';
import { ChartContainer } from './ChartContainer';
import { useRewardsFnOverTimeChartData } from './hooks/useRewardsFnOverTimeChartData';
import { useRollingMetricsData } from './hooks/useRollingMetricsData';

const valueToLabel = {
    // num_stores_ordered: '# Stores',
    // num_orders: '# Orders',
    // gross_revenue: 'Gross Revenue',
    // avg_range_purchased: 'Average range purchased',
    // avg_range_recommended: 'Average range recommended',
    orders_influenced: '% Orders Influenced',
    products_influenced: 'Influenced Products',
    precision: 'Precision',
    recall: 'Recall',
    mape: 'MAPE',
    // num_influenced_orders: '# Influenced Orders',
};

export const RewardFnOverTime = ({
    agentManagerModelId,
    agentManagerModelIsLoading,
}: {
    agentManagerModelId: string;
    agentManagerModelIsLoading?: boolean;
}) => {
    const [dataSource, setDataSource] = useState<keyof typeof valueToLabel>('orders_influenced');
    const [rollingMetricsData, rollingMetricsError, rollingMetricsIsLoading] =
        useRollingMetricsData({
            agentManagerId: agentManagerModelId,
        });

    const loading = agentManagerModelIsLoading || rollingMetricsIsLoading;

    const dataFilteredFromNulls = useMemo(
        () => rollingMetricsData?.filter(row => !isNaN(row.rolling_average_reward)) ?? [],
        [rollingMetricsData],
    );
    const dataFiltered = useMemo(
        () => dataFilteredFromNulls?.filter(row => row.reward_name === dataSource) ?? [],
        [dataSource, dataFilteredFromNulls],
    );

    const { datasets, labels } = useRewardsFnOverTimeChartData(dataFiltered);

    const dataSourceOptions: SelectItem[] = useMemo(
        () =>
            Object.values(
                dataFilteredFromNulls?.reduce(
                    (acc, item) =>
                        !valueToLabel[item.reward_name]
                            ? acc
                            : {
                                  ...acc,
                                  [item.reward_name]: {
                                      value: item.reward_name,
                                      label: valueToLabel[item.reward_name],
                                  },
                              },
                    {},
                ) ?? {},
            ),
        [dataFilteredFromNulls],
    );

    const onChange = (v: keyof typeof valueToLabel) => setDataSource(v);

    return (
        <ChartContainer
            error={rollingMetricsError && 'Failed to fetch data'}
            loading={loading}
            noDataFound={dataFiltered.length === 0}
            title={`'${valueToLabel[dataSource]} Influenced' over time`}
            action={
                <div className="flex h-fit items-center gap-2">
                    <Select
                        data={dataSourceOptions}
                        value={valueToLabel?.[dataSource] ?? dataSource}
                        onChange={onChange}
                        width={190}
                        cleanDesign
                    />
                </div>
            }
        >
            <LineChart
                className="mt-3"
                data={{ datasets, labels }}
                legend={{ display: false }}
                yAxis={{
                    ticks: {
                        format: {
                            style: 'percent',
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 2,
                        },
                    },
                }}
            />
        </ChartContainer>
    );
};
