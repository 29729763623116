import { Tooltip } from '@mantine/core';
import _ from 'lodash';
import { DownloadSimple } from 'phosphor-react';
import { useMemo } from 'react';
import { ChartDataset, Color } from 'chart.js';
import color from 'color';
import BarChart from '../../../utils/charts/barChart';
import { ChartContainer } from './ChartContainer';
import { useAllocationMetricsData } from './hooks/useAllocationMetrics';
import { categoryColors } from '../../../../constants/colors';

export const AllocationByUserSegment = ({
    agentManagerModelId,
    agentManagerModelIsLoading,
}: {
    agentManagerModelId: string;
    agentManagerModelIsLoading?: boolean;
}) => {
    const [allocationMetricsData, allocationMetricsError, allocationMetricsIsLoading] =
        useAllocationMetricsData({
            agentManagerId: agentManagerModelId,
        });

    const chartData: any = useMemo(() => {
        const groupBySegment = _.groupBy(allocationMetricsData, 'context__segment');

        const groupByModel = _.groupBy(allocationMetricsData, 'action__model_id');

        return {
            datasets: Object.entries(groupByModel).map(
                ([model_id, metrics]) =>
                    ({
                        label: metrics[0].model_name,
                        data: Object.values(groupBySegment).map(
                            segmentMetrics =>
                                segmentMetrics.filter(m => m.action__model_id === model_id)[0]
                                    ?.rolling_allocation_percent ?? 0,
                        ),
                        backgroundColor: categoryColors[model_id],
                        borderColor: categoryColors[model_id],
                        hoverBackgroundColor: color(categoryColors[model_id]).darken(0.15).hex(),
                    }) as ChartDataset<'bar'>,
            ),
            labels: Object.keys(groupBySegment),
        };
    }, [allocationMetricsData]);

    return (
        <ChartContainer
            loading={allocationMetricsIsLoading || agentManagerModelIsLoading}
            error={allocationMetricsError && 'Failed to fetch data'}
            title="Allocation by user segment"
            noDataFound={allocationMetricsData.length === 0}
            action={
                <div className="flex items-center gap-4">
                    <Tooltip label="Downloading allocation data will be active soon." withArrow>
                        <DownloadSimple size={22} />
                    </Tooltip>
                </div>
            }
        >
            <BarChart
                className="mt-3"
                data={chartData}
                stackedBars
                legend={{ display: false }}
                tooltip={{
                    usePointStyle: true,
                    callbacks: {
                        labelPointStyle: context => ({
                            pointStyle: 'circle',
                            rotation: 0,
                        }),
                        labelColor: context => ({
                            borderColor: context.dataset.borderColor as Color,
                            backgroundColor: context.dataset.backgroundColor as Color,
                        }),
                        label: context => `Model: ${context.dataset?.label}`,
                    },
                }}
            />
        </ChartContainer>
    );
};
