import { BOREALIES_API_URL, SPACEPORT_API_URL } from '../../../../buildConfig/processEnv';

export const DjApiPaths = {
    ModelMetrics: (agentManagerId: string) =>
        `${SPACEPORT_API_URL}/spaceport/api/dj/model_performance/model/${agentManagerId}`,
    AllocationMetrics: (agentManagerId: string) =>
        `${SPACEPORT_API_URL}/spaceport/api/dj/metrics/user_allocations_by_segment/model/${agentManagerId}`,
    CreateModel: (agentManagerId: string) =>
        `${SPACEPORT_API_URL}/spaceport/api/dj/${agentManagerId}/add_model`,
    RemoveModel: (agentManagerId: string) =>
        `${SPACEPORT_API_URL}/spaceport/api/dj/${agentManagerId}/remove_model`,
    Config: (agentManagerId: string) =>
        `${SPACEPORT_API_URL}/spaceport/api/dj/config/${agentManagerId}/`,
    RollingMetrics: (agentManagerId: string) =>
        `${SPACEPORT_API_URL}/spaceport/api/dj/metrics/over_time/${agentManagerId}`,
    GetAgentManagerModel: (useCase: string, namespace: string) =>
        `${SPACEPORT_API_URL}/spaceport/api/dj/use_case/${useCase}/namespace/${namespace}/model`,
    GetAgentManagerModelList: (useCase: string, namespace: string) =>
        `${SPACEPORT_API_URL}/spaceport/api/dj/use_case/${useCase}/namespace/${namespace}/model/list`,
};
export const DataCatalogApiPaths = {
    ModelOutputs: (namespace: string, useCase: string) =>
        `${BOREALIES_API_URL}/spaceport/api/model_output/?organization=borealis&namespace=${namespace}&${useCase ? `use_case=${useCase}` : ''}`,
};
