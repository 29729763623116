import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAppContext } from '../../../../../contexts/appContext';
import { DjApiPaths } from '../ApiPaths';

export function useCreateModel({ agentManagerId }: { agentManagerId: string }) {
    const { user } = useAppContext();
    const queryClient = useQueryClient();

    const createModel = async modelId => {
        const url = new URL(DjApiPaths.CreateModel(agentManagerId));
        const headers = {
            accept: 'application/json',
            Authorization: `Bearer ${user.siberiaToken}`,
            'Content-Type': 'application/json',
        };
        const data = {
            model_id: modelId,
        };

        const response = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error('An error occurred while creating the model.');
        }
        queryClient.invalidateQueries({
            queryKey: [DjApiPaths.ModelMetrics(agentManagerId)],
        });
        queryClient.invalidateQueries({
            queryKey: [DjApiPaths.AllocationMetrics(agentManagerId)],
        });
        queryClient.invalidateQueries({
            queryKey: [DjApiPaths.Config(agentManagerId)],
        });
    };

    const { mutate, error, isPending } = useMutation({ mutationFn: createModel });

    return {
        createModel: mutate,
        createModelIsLoading: isPending,
        createModelError: error as Error,
    };
}
