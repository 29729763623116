import React, { useMemo } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import {
    BarElement,
    CategoryScale,
    ChartData,
    Chart as ChartJS,
    ChartOptions,
    Filler,
    Legend,
    LinearScale,
    LogarithmicScale,
    Title,
    Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

import _ from 'lodash';
import {
    border,
    defaultChartTextColor,
    defaultTitleBar,
    defaultTooltipBar,
} from '../../../constants/chart';

import type {
    BarChartOptionsPlugins,
    BarChartOptionsScale,
    BarChartType,
    MostCommonBarDataType,
    PointType,
} from '../../../types/components/charts';
import { ChartDataPoint } from '../../../types/ChartDataPoint';

// This is not maintainable, need to be fixed
type BarChartProps = {
    data: ChartData<'bar', (MostCommonBarDataType | PointType | ChartDataPoint)[]>;
    chartTitle?: string | string[];
    xAxis?: Partial<BarChartOptionsScale['x']>;
    yAxis?: Partial<BarChartOptionsScale['y']>;
    extraYAxis?: Record<string, Partial<BarChartOptionsScale['y']>>;
    min?: number;
    max?: number;
    className?: string;
    tooltip?: BarChartOptionsPlugins['tooltip'];
    legend?: BarChartOptionsPlugins['legend'];
    id?: string;
    datalabels?: BarChartOptionsPlugins['datalabels'];
    annotation?: BarChartOptionsPlugins['annotation'];
    title?: BarChartOptionsPlugins['title'];
    showDatalabels?: boolean;
    horizontalBars?: boolean;
    stackedBars?: boolean;
    onClick?: ChartOptions<'bar'>['onClick'];
    onHover?: ChartOptions<'bar'>['onHover'];
    plugins?: ChartOptions<'bar'>['plugins'];
    onClickChart?: React.MouseEventHandler<HTMLCanvasElement>;
    options?: ChartOptions<'bar'>;
    datasetIdKey?: string;
    layout?: ChartOptions<'bar'>['layout'];
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    annotationPlugin,
);

const BarChart = React.forwardRef(
    (
        {
            data,
            chartTitle,
            xAxis = {},
            yAxis = {},
            className,
            tooltip = {},
            id,
            legend = {},
            max,
            min,
            datalabels,
            showDatalabels = false,
            horizontalBars,
            annotation,
            title,
            extraYAxis,
            onClick,
            onHover,
            stackedBars = false,
            plugins,
            onClickChart,
            options,
            datasetIdKey,
            layout,
        }: BarChartProps,
        ref: React.MutableRefObject<BarChartType>,
    ) => {
        const chartOptions = useMemo<ChartOptions<'bar'>>(() => {
            const y: BarChartOptionsScale['y'] = {
                border,
                title: {
                    color: defaultChartTextColor,
                    display: true,
                    ...yAxis.title,
                },
                stacked: stackedBars,
                ...yAxis,
            };
            let extraYs = {};
            if (extraYAxis) {
                extraYs = Object.entries(extraYAxis).reduce(
                    (acc: typeof extraYAxis, [axisKey, axisData]) => ({
                        ...acc,
                        [axisKey]: {
                            ...y,
                            ...axisData,
                            title: { ...y.title, ...axisData.title },
                            type: axisData.type || 'linear',
                            border: {
                                ...border,
                                ...yAxis.border,
                                ...(axisData?.border || {}),
                            },
                        },
                    }),
                    {},
                );
            }
            let delayed = 0;
            const innerOptions = {
                layout,
                responsive: true,
                maintainAspectRatio: false,
                hover: {
                    mode: 'nearest',
                },
                indexAxis: horizontalBars ? 'y' : undefined,
                onClick,
                onHover,
                elements: {
                    bar: {
                        borderColor: 'transparent',
                        borderWidth: 0,
                        pointStyle: 'rectRounded',
                        barPercentage: 0.9,
                    },
                },
                animation: {
                    onComplete() {
                        delayed = -1;
                    },
                    delay(ctx) {
                        if (ctx.type !== 'data' || delayed === -1 || ctx.mode !== 'default') {
                            return 0;
                        }
                        let delaying = 1500 / ctx.dataset.data.length;
                        delaying = delaying > 150 ? 150 : delaying;
                        return ctx.dataIndex * delaying + ctx.datasetIndex * 50;
                    },
                    easing: 'linear',
                },
                plugins: _.defaultsDeep(
                    {
                        annotation,
                        datalabels: {
                            display: showDatalabels,
                            color: context =>
                                (Array.isArray(context.dataset.backgroundColor) &&
                                    context.dataset.backgroundColor?.[context.dataIndex]) ||
                                context.dataset.backgroundColor,
                            align: context => {
                                let val = context.dataset.data[context.dataIndex] as
                                    | { x: number }
                                    | number;
                                if (typeof val !== 'number') {
                                    val = val.x;
                                }
                                return (val >= 0 && 'end') || 'start';
                            },
                            anchor: context => {
                                let val = context.dataset.data[context.dataIndex] as
                                    | { x: number }
                                    | number;
                                if (typeof val !== 'number') {
                                    val = val.x;
                                }
                                return (val >= 0 && 'end') || 'start';
                            },
                            backgroundColor: '#1a1f2639',
                            ...datalabels,
                            font: {
                                size: 11,
                                weight: 'bold',
                                ...(datalabels?.font || {}),
                            },
                        },
                        legend: {
                            display: true,
                            ...legend,
                            labels: {
                                usePointStyle: true,
                                pointStyle: 'rectRounded',
                                ...(legend?.labels || {}),
                            },
                        },
                        title: {
                            ...defaultTitleBar,
                            ...title,
                            display: !!chartTitle,
                            text: chartTitle,
                            font: {
                                ...defaultTitleBar.font,
                                ...(title?.font || {}),
                            },
                        },
                        tooltip: {
                            ...defaultTooltipBar,
                            ...tooltip,
                            callbacks: {
                                ...defaultTooltipBar.callbacks,
                                ...(tooltip?.callbacks || {}),
                            },
                        },
                    },
                    plugins,
                ),
                scales: {
                    x: {
                        border,
                        title: {
                            color: defaultChartTextColor,
                            ...(xAxis?.title || {}),
                        },
                        suggestedMin:
                            horizontalBars && Math.abs(min) * 2 < max ? min * 3 : min * 1.25,
                        suggestedMax: horizontalBars && max * 1.25,
                        stacked: stackedBars,
                        ...xAxis,
                    },
                    y: {
                        title: {
                            color: defaultChartTextColor,
                            display: true,
                            ...(yAxis?.title || {}),
                        },
                        border: {
                            ...border,
                            ...yAxis.border,
                        },
                        beginAtZero: true,
                        suggestedMin:
                            !horizontalBars && Math.abs(min) * 2 < max ? min * 3 : min * 1.25,
                        suggestedMax: !horizontalBars && max * 1.25,
                        stacked: stackedBars,
                        ...yAxis,
                    },
                    ...extraYs,
                },
            };

            return _.defaultsDeep(options, innerOptions);
        }, [
            yAxis,
            stackedBars,
            extraYAxis,
            horizontalBars,
            onClick,
            onHover,
            annotation,
            showDatalabels,
            datalabels,
            legend,
            title,
            chartTitle,
            xAxis,
            min,
            max,
            tooltip,
            plugins,
            options,
            layout,
        ]);

        return (
            <Bar
                ref={ref}
                datasetIdKey={datasetIdKey}
                options={chartOptions}
                data={data}
                plugins={[ChartDataLabels]}
                className={className}
                id={id}
                onClick={onClickChart}
            />
        );
    },
);

export default BarChart;
export type { BarChartProps };
